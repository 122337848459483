<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>Edit Award And Trophies</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          Add New Trophy
        </b-button>
      </div>
      <b-row align-content="center" v-if="settingDetails">
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            v-if="!settingDetails.awards || settingDetails.awards.length == 0"
          >
            <p class="text-center">No Award/Trophy Available</p>
          </div>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="my-1"
                v-for="award in settingDetails.awards"
                :key="award.priority"
              >
                <b-img
                  class="cursor-pointer image-thumbnail"
                  @click="renderImageModal(award)"
                  :src="handleImageUrl(award)"
                  alt=""
                  style="object-fit: object-contain"
                  height="auto"
                  width="200"
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" v-b-modal.modal-create>
          Add New Award/Trophy
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="Create Award/Trophy"
      ok-title="Create"
      cancelTitle="Dismiss"
      @ok="pushNewAward"
    >
      <b-form-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Page Link" label-for="link">
              <b-form-input v-model="newAwardDetails.link"></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Award/Trophy Image" label-for="awardImage">
              <b-form-file
                id="awardImage"
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                placeholder="Choose Photo"
                @input="makeBase64Pic"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-form-row>
      <div class="w-100 d-flex align-items-center justify-content-center my-2">
        <b-img
          v-if="imagePreview"
          :src="imagePreview"
          height="auto"
          width="400"
          class="rounded image-preview"
        />
      </div>
    </b-modal>
    <library-image-modal
      :tempMedia="tempImage"
      @refetch="getSetting"
    ></library-image-modal>
  </b-overlay>
</template>
<script>
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
export default {
  title: "Awards And Trophies Setting",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      settingDetails: null,
      newAwardDetails: {
        base64: null,
        objectId: 1,
        type: 18, // 18 is for Awards
        priority: null,
        imageTitle: null,
        link: null,
      },
      imagePreview: null,
      tempImage: null,
    };
  },
  methods: {
    async getSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    pushNewAward() {
      this.newAwardDetails.priority =
        this.settingDetails.awards?.length > 0
          ? this.settingDetails.awards[this.settingDetails.awards.length - 1]
              .priority + 1
          : 1;

      this.sendCreateFileRequest();
    },
    renderImageModal(award) {
      this.tempImage = {
        pictureUrl: award.base64,
      };
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(award) {
      if (award.base64) {
        return `${Helper.baseUrl}/${award.base64}`;
      }
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.newAwardDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.newAwardDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successfully",
                icon: "CheckIcon",
                variant: "success",
                text: "File Uploaded Successfully",
              },
            });
            _this.getSetting();
            _this.imagePreview = null;
            _this.newAwardDetails = {
              base64: null,
              objectId: 1,
              type: 18, // 18 is for Awards
              priority: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    LibraryImageModal,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import Helper from "@/libs/Helper";
</script>
<style>
.image-preview {
  max-height: 400px;
  height: 400px;
  object-fit: contain;
}
.image-thumbnail {
  max-height: 130px;
  height: 130px;
  object-fit: contain;
}
</style>
